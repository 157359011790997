<template>
  <layout-modal
    :classes="classes" 
    visible
    closable
    :loading="loading"
    @close="close">

    <div class="flow">
      <forms-file
        :placeholder="$t('user-portal.user_receipt_placeholder')"
        class="receipt__form"
        accept=".png,.jpeg,.jpg"
        v-model="file"
        @input="onInput">
      </forms-file>

      <div v-if="isErrored">
        <forms-label intent="danger" v-for="(error, i) in getErrors('image')" :key="i">{{ error }}</forms-label>
      </div>

      <img
        :src="previewSrc"
        alt=""
        v-if="previewSrc"
      />
    </div>

    <template v-slot:footer>
      <actions-button
        @click="close"
        :size="$pepper.Size.S"
      >{{$t('user-portal.cancel')}}</actions-button>

      <actions-button
        :appearance="$pepper.Appearance.PRIMARY"
        @click="onConfirm"
        :loading="loading"
        :disabled="loading || !canConfirm"
        :size="$pepper.Size.S"
      >{{$t('user-portal.confirm')}}</actions-button>
    </template>

  </layout-modal>
</template>

<script>
import LayoutModal from '@/components/layouts/modal'
import MixinError from '@/helpers/errors'

export default {
  name: 'ModalReceipt',

  inject: ['$user'],

  mixins: [ MixinError ],

  props: {
    receipt: {},
    errors: {},
    loading: {}
  },

  data() {
    return {
      file: null,
      previewSrc: null,
      key: 1
    }
  },

  components: {
    LayoutModal
  },

  computed: {
    classes() {
      return {
        'view-user-receipt': true
      }
    },

    canConfirm() {
      return this.file !== null
    },

    isErrored() {
      return this.getErrors('image').length > 0
    }
  },

  methods: {
    close() {
      if (this.previewSrc) {
        URL.revokeObjectURL(this.previewSrc)
      }
      this.$emit('close')
    },

    onConfirm() {
      this.$emit('confirm', this.file)
    },

    onInput() {
      if (this.previewSrc) {
        URL.revokeObjectURL(this.previewSrc)
      }
      this.previewSrc = URL.createObjectURL(this.file.src)
    }
  }
}
</script>
