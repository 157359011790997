<template>
  <layout-modal
    :classes="classes" 
    visible
    closable
    :loading="loading"
    @close="close">
    <data-table
      :columns="columns"
      :rows="rows"
    />
    <!--<table>
      <thead>
        <tr>
          <th>{{ $t('user-portal.receipt_item_quantity') }}</th>
          <th>{{ $t('user-portal.receipt_item_label') }}</th>
          <th>{{ $t('user-portal.receipt_item_price') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in receipt.lines">
          <td>{{ item.quantity }}x</td>
          <td>{{ item.label }}</td>
          <td>{{ item.price }}</td>
        </tr>
      </tbody>
    </table>-->
  </layout-modal>
</template>

<script>
import LayoutModal from '@/components/layouts/modal'

export default {
  name: 'ModalReceipt',

  inject: ['$user'],

  props: {
    receipt: {},
  },

  data() {
    return {
      loading: false,
      key: 1
    }
  },

  components: {
    LayoutModal
  },

  computed: {
    classes() {
      return {
        'view-user-receipt': true
      }
    },

    columns() {
      return [
        {
          fqn: 'user-receipt-label',
          label: this.$t('user-portal.receipt_item_label')
        },
        {
          fqn: 'user-receipt-price',
          label: this.$t('user-portal.receipt_item_price')
        }
      ]
    },

    rows() {
      return this.receipt.lines.map((line) => {
        return {
          data: [
            {
              fqn: 'user-receipt-label',
              content: line.label
            },
            {
              fqn: 'user-receipt-price',
              content: line.price
            }
          ]
        }
      })
    }
  },

  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
