<template>
  <main>
    <div class="container">
      <!-- Header -->
      <header class="user__header">
        <navigations-breadcrumb :children="breadcrumbs" />

        <div class="row">
          <h1 class="user__title">{{ $t('user-portal.user_receipts_title') }}</h1>
        </div>
      </header>

      <!-- Loading -->
      <div
        v-if="loading"
        class="user__loader">
        <ui-loader />
      </div>

      <!-- Body:Content -->
      <div
        v-if="!loading"
        class="user__body flow">
        <div class="-grid">
          <div
            class="user-card -is-empty"
            @click="onAdd">
            <div class="user-card__figure">
              <ui-icon
                class="icon"
                glyph="plus"
              />
            </div>

            <p class="user-card__label">{{ $t('user-portal.add_new_receipt') }}</p>
          </div>

          <card-receipt
            v-for="receipt in receipts"
            :key="receipt.id"
            :receipt="receipt"
            @click="onShow"
          />

          <div
            v-if="showMore && !fetching"
            class="user-card -is-empty"
            @click="onShowMore">
            <div class="user-card__figure">
              <ui-icon
                class="icon"
                glyph="dots"
              />
            </div>

            <p class="user-card__label">{{ $t('user-portal.show_more_receipts') }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Receipt -->
    <modal-receipt
      v-if="currentReceipt"
      :receipt="currentReceipt"
      @close="onClose"
    />

    <!-- Modal Upload -->
    <modal-upload
      v-if="showUpload"
      :errors="errors"
      :loading="uploading"
      @confirm="onConfirm"
      @close="onCloseUpload"
    />
  </main>
</template>

<script>
import CardReceipt from '../../components/ui/card-receipt'
import ModalReceipt from '../../components/modals/receipt'
import ModalUpload from '../../components/modals/receipt-upload'

import NavigationsBreadcrumb from '@/components/navigations/breadcrumb'

import MixinError from '@/helpers/errors'

export default {
  name: 'UserReceipts',

  components: {
    CardReceipt,
    ModalReceipt,
    ModalUpload,
    NavigationsBreadcrumb
  },

  inject: [
    '$embed',
    '$user'
  ],

  mixins: [ MixinError ],

  data() {
    return {
      breadcrumbs: [
        { label: this.$t('user-portal.user_account_title'), href: { name: 'sayl-user-portal.home' } },
        { label:  this.$t('user-portal.user_receipts_title'), href: '#' }
      ],
      currentReceipt: null,
      showUpload: false,
      errors: {},
      key: 1,
      uploading: false,
      loading: false,
      page: 1,
      limit: 10,
      fetching: false
    }
  },

  computed: {
    receipts() {
      return this.$user.receipts.data || []
    },

    pagination() {
      return this.$user.receipts.pagination || {}
    },

    showMore() {
      const totalPages = this.$basil.get(this.pagination, 'total_pages', 1)
      const currentPage = this.$basil.get(this.pagination, 'current_page', 1)
      return totalPages > currentPage
    }
  },

  methods: {
    onAdd() {
      this.errors = {}
      this.showUpload = true
    },

    onClose() {
      this.currentReceipt = null
      this.errors = {}
    },

    onCloseUpload() {
      this.showUpload = false
      this.errors = {}
    },

    async onConfirm(file) {
      this.errors = {}
      this.uploading = true

      const data = new FormData()
      data.append('image', file.src)

      try { 
        await this.$user.uploadReceipt(data)

        this.showUpload = false
        this.loading = true

        await this.$user.getReceipts({ page: 1, limit: this.limit })
        this.page = this.$basil.get(this.pagination, 'current_page')

        this.currentReceipt = this.receipts[0]
      } catch(e) {
        this.handleErrors(e)
      } finally {
        this.loading = false
        this.uploading = false
      }
    },

    async onShowMore() {
      this.fetching = true
      try {
        await this.$user.getReceipts({ page: this.page + 1, limit: this.limit }, true)
        this.page = this.$basil.get(this.pagination, 'current_page')
      } catch(e) {
        $console.error(e)
      } finally {
        this.fetching = false
      }
    },

    onShow(receipt) {
      this.currentReceipt = receipt
    },

    async reset() {
      try {
        this.loading = true
        await this.$user.getReceipts({ page: 1, limit: this.limit })
        this.page = this.$basil.get(this.pagination, 'current_page')
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
      }
    }
  },

  mounted() {
    this.errors = {}
    this.reset()
  },
}
</script>
