<template>
  <article v-bind="attrs">
    <div class="user-card__row receipt" @click="() => $emit('click', receipt)">
      <img
        :src="receipt.picture"
        v-if="receipt.picture"
        alt=""
        class="receipt__picture"
      />
    </div>
  </article>
</template>

<script>
export default {
  name: 'UserReceiptCard',

  props: {
    receipt: {
      type: Object
    }
  },

  computed: {
    attrs() {
      return {
        class: this.classes,
        href: '#'
      }
    },

    classes() {
      return {
        'user-card-receipt': true,
        'user-card': true
      }
    }
  }
}
</script>
